module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"zestyprints","short_name":"zestyprints","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"standalone","icon":"src/assets/zestyprintsicon.svg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
